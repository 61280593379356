<template>
  <div class="address-map">
    <Header text="确定" @textClick="onConfirm"/>
    <!-- 地图 -->
    <div class="map-container" id="mapContainer"></div>

    <div class="address-list">
      <div class="content">
        <div v-if="!isReady" style="margin: 32px auto;"><Loading /></div>
        <div 
          class="address" 
          :class="{'active': item.uid === activeAddressUid}"
          v-for="item in addressList"
          :key="item.uid"
          @click="onSelectAddress(item)">
          {{item.title}}({{item.address}})
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Loading } from 'vant';
import { initMap, searchCurrentCenter, addMapMarker } from '../../utils/map';
import Header from '@/components/header/headerWithText/index.vue';

export default {
  components: {
    Header,
    Loading,
  },
  data() { 
    return {
      addressList: [],
      activeAddress: {},
      activeAddressUid: '',
      isReady: false,
    }
  },
  async mounted() {
    const { map, currentLocation } = await initMap('mapContainer', {
      currentLocation: true,
    });
    this._map = map;
    this._currentLocatoin = currentLocation || {};
    this.isReady = true;
    this._map.addEventListener("moveend", this.updateAddressList);
    this.updateAddressList();
  },
  methods: {
    async updateAddressList() {
      this.updateMarker(this._map.getCenter());
      const addressList = await searchCurrentCenter(this._map);
      this.addressList = addressList;
    },
    onSelectAddress(item) {
      this.activeAddress = item;
      this.activeAddressUid = item.uid;
      this.updateMarker(item.point);
      // mapNavigation({
      //   originLongitude: this._currentLocatoin.lng,
      //   originLatitude: this._currentLocatoin.lat,
      //   longitude: item.point.lng,
      //   latitude: item.point.lat,
      //   title: item.title,
      //   content: item.address
      // });
    },
    onConfirm() {
      if (!this.activeAddress.uid) {
        return this.$toast('请选择地址');
      }
      this.$emit('confirm', {
        address: this.activeAddress
      });
    },
    updateMarker(point) {
      const {lng, lat} = point;
      this._marker = addMapMarker({
        map: this._map, 
        longitude: lng, 
        latitude: lat,
        marker: this._marker,
      });
    },
  }
}
</script>

<style lang="scss" scoped>
.address-map {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  .map-container {
    width: 100%;
    flex: 1;
  }
  .address-list {
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    height: 200px;
    padding: 16px 12px;
    background-color: #fff;
    z-index: 10;
    .content {
      width: 100%;
      height: 100%;
      overflow-y: auto;
      background-color: #FAFBFC;
    }
    .address {
      width: 100%;
      line-height: 40px;
      padding: 0 24px;
      box-sizing: border-box;
      overflow: hidden;
      text-overflow: ellipsis;
      height: 40px;
      text-align: left;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      &.active {
        background-color: #CD001F;
        color: #fff;
      }
    }
  }
}
</style>
